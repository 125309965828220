/*
 * @Descripttion: 
 * @Author: BZR
 * @Date: 2023-08-01 12:07:26
 * @LastEditTime: 2024-03-11 11:13:18
 */
/**
 *  项目的配置文件
 */
//因为如果从common.js引入，而request又引入common, common也引入request。会出问题，所以直接在此定义getQueryVariable
const getQueryVariable = (variable) => {
     let query = window.location.search.substring(1);
     let vars = query.split("&");
     for (let i = 0; i < vars.length; i++) {
          let pair = vars[i].split("=");
          if (pair[0] == variable) {
               return pair[1];
          }
     }
     return false;
}

//确保第一时间获取到 identity值，要不然打开则undefined
export const getIdentity = () => {
     if (localStorage.identity && localStorage.identity != 'false') {
          return localStorage.identity
     } else {
          if (getQueryVariable('storeId')) return 'seller'
          if (getQueryVariable('adminId')) return 'admin'
     }
}

export const type2SourceUrl = () => {
     const type = getIdentity()
     if (type === 'seller') return process.env.NODE_ENV === 'development' ? 'http://purchase-supplier.test2.yunsom.cn/' : 'https://supplier.zhi-tu.cn/'
     return process.env.NODE_ENV === 'development' ? 'http://purchase-admin.test2.yunsom.cn/' : 'https://admin.zhi-tu.cn/'
}

export const identity = getIdentity()

// export const apiUrl = `https://${getIdentity()}.55sld.com/`//接口请求地址
// export const chatUrl = 'wss://im.55sld.com'//客服地址
// export const sourceUrl = `https://${getIdentity()}.55sld.com/`;//来源地址，当用户失去登录态时需跳转到来源登录页
// export const pcUrl = 'https://www.55sld.com/'  //没有PC终端，这里无需配置，直接留空即可

export const apiUrl = process.env.NODE_ENV === 'development' ? 'http://purchase-pc.test2.yunsom.cn/' : `https://${getIdentity() === 'seller' ? 'supplier.' : 'admin.'}zhi-tu.cn/`//接口请求地址
export const chatUrl = 'wss://im.zhi-tu.cn/'//客服地址
export const sourceUrl = type2SourceUrl();//来源地址，当用户失去登录态时需跳转到来源登录页
export const pcUrl = process.env.NODE_ENV === 'development' ? 'https://zhi-tu.cn/' : 'http://purchase-pc.test2.yunsom.cn/'  //没有PC终端，这里无需配置，直接留空即可
export const curLang = 'zh';//当前语言,zh:中文，若为其他语言，需要对应/src/assets/language下面的文件名


